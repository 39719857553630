/* Default icon size */
.icon-facebook, .icon-instagram, .icon-youtube {
  width: 24px;
  height: 24px;
}

.icon-facebook svg, .icon-instagram svg, .icon-youtube svg {
  width: 100%;
  height: 100%;
}

/* Mobile view adjustments */
@media (max-width: 767px) {
  .social-icons-mobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white; /* Adjust background color as needed */
    display: flex;
    justify-content: center;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }

  .social-icons-mobile ul {
    display: flex;
    gap: 20px;
  }
}

/* Large screen adjustments */
@media (min-width: 1024px) {
  .social-icons-desktop {
    display: flex;
    justify-content: center;
    gap: 40px; /* Adjust gap as needed */
    padding: 20px 0;
  }

  .social-icons-desktop ul {
    display: flex;
    gap: 30px; /* Adjust gap as needed */
  }

  .icon-facebook, .icon-instagram, .icon-youtube {
    width: 48px; /* Larger icon size for bigger screens */
    height: 48px;
  }

  .icon-facebook svg, .icon-instagram svg, .icon-youtube svg {
    width: 100%;
    height: 100%;
  }
}

/* Extra large screen adjustments (TVs, very large monitors) */
@media (min-width: 1440px) {
  .social-icons-desktop {
    gap: 60px; /* Adjust gap as needed */
    padding: 30px 0;
  }

  .icon-facebook, .icon-instagram, .icon-youtube {
    width: 64px; /* Even larger icon size for extra large screens */
    height: 64px;
  }

  .icon-facebook svg, .icon-instagram svg, .icon-youtube svg {
    width: 100%;
    height: 100%;
  }
}

/* Extra extra large screen adjustments (1920px and above) */
@media (min-width: 1920px) {
  .social-icons-desktop {
    gap: 80px; /* Larger gap for very large screens */
    padding: 40px 0;
  }

  .icon-facebook, .icon-instagram, .icon-youtube {
    width: 120px;
    height: 120px;
  }
  
  .icon-facebook svg, .icon-instagram svg, .icon-youtube svg {
    width: 100%;
    height: 100%;
  }
}
