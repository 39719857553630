/* Base styles for social icons desktop */
.social-icons-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Default gap */
    padding: 10px 0; /* Default padding */
  }
  
  /* Small screens (Tablets and below) */
  @media (max-width: 1023px) {
    .social-icons-desktop {
      display: none; /* Hide on smaller screens, if you have a mobile version */
    }
  }
  
  /* Medium to large screens (desktops, laptops) */
  @media (min-width: 1024px) and (max-width: 1439px) {
    .social-icons-desktop {
      gap: 30px; /* Increase gap for medium-large screens */
      padding: 15px 0; /* Increase padding */
    }
  
    .icon-facebook, .icon-instagram, .icon-youtube {
      width: 48px; /* Larger icon size for medium-large screens */
      height: 48px;
    }
  }
  
  /* Extra large screens (very large monitors, TVs) */
  @media (min-width: 1440px) {
    .social-icons-desktop {
      gap: 40px; /* Increase gap for extra-large screens */
      padding: 20px 0; /* Increase padding */
    }
  
    .icon-facebook, .icon-instagram, .icon-youtube {
      width: 56px; /* Larger icon size for extra-large screens */
      height: 56px;
    }
  }
  
  /* Ultra-large screens (1920px and above) */
  @media (min-width: 1920px) {
    .social-icons-desktop {
      gap: 60px; /* Larger gap for ultra-large screens */
      padding: 30px 0; /* Increase padding */
    }
  
    .icon-facebook, .icon-instagram, .icon-youtube {
      width: 64px; /* Even larger icon size for ultra-large screens */
      height: 64px;
    }
  }
  