* {
    box-sizing: border-box;
  }
  *:before, *:after {
    box-sizing: border-box;
  }
  .flower {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  .flower .flower__center {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background: #FFBB00;
    position: relative;
    z-index: 10;
    will-change: transform;
  }
  .flower .flower__leaves {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    will-change: transform;
  }
  .flower .flower__leaf {
    width: 70%;
    transform-origin: center bottom;
    position: absolute;
    will-change: transform;
  }
  .flower .flower__leaf .flower__leaf-inner {
    transform-origin: center bottom;
    transform: scale(0);
    will-change: transform;
  }
  .flower .flower__leaf svg path {
    fill: #fff;
  }
 .flower-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 100vh;
    background: #338763;
  }
 