.loading-fallback {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
  }
  
  .overflow-x-hidden {
    overflow-x: hidden;
  }
  